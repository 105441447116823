import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/nextjs/src/app/main/AnnouncementsSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/nextjs/src/app/main/HeroSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/nextjs/src/app/main/HighlightsSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/nextjs/src/app/main/TestsLetters.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/nextjs/src/components/ScrollWithCarets.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
