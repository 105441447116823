'use client';
import React from 'react';
import { toast as toastFn, resolveValue, Toast, Toaster, ToastOptions, ToastType } from 'react-hot-toast';
import { Transition } from '@headlessui/react';
import { HiCheckCircle, HiExclamationCircle, HiInformationCircle, HiX, HiXCircle } from 'react-icons/hi';
import useWindowSize from 'src/hooks/useWindowSize';

type Renderable = React.JSX.Element | string | null;
type ValueFunction<TValue, TArg> = (arg: TArg) => TValue;
type ValueOrFunction<TValue, TArg> = TValue | ValueFunction<TValue, TArg>;
type Message = ValueOrFunction<Renderable, Toast>;

const toastIcon = (type: ToastType) => {
    switch (type) {
        case 'success':
            return <HiCheckCircle className="text-success" />;
        case 'error':
            return <HiXCircle className="text-danger" />;
        // warning
        case 'loading':
            return <HiInformationCircle className="text-info" />;
        // info
        case 'custom':
            return <HiExclamationCircle className="text-warning" />;
        default:
            return null;
    }
};

export function TailwindToaster() {
    const windowSize = useWindowSize();
    const isMobile = (windowSize.width || 0) <= 640;

    return (
        <Toaster
            containerClassName={`${isMobile ? '' : 'container mx-auto '}!top-nav-sm-height md:!top-nav-md-height mt-2`}
            position={isMobile ? 'top-center' : 'top-right'}>
            {(t) => (
                <Transition
                    as="div"
                    appear
                    show={t.visible}
                    className="flex transform items-center gap-4 rounded-lg bg-white px-4 py-2 text-sm shadow-lg md:text-base"
                    enter="transition-all duration-150"
                    enterFrom="opacity-0 scale-50"
                    enterTo="opacity-100 scale-100"
                    leave="transition-all duration-150"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-75">
                    {t.icon}
                    <div className="flex-1">{resolveValue(t.message, t)}</div>
                    <div className="cursor-pointer" onClick={() => toastFn.dismiss(t.id)}>
                        <HiX />
                    </div>
                </Transition>
            )}
        </Toaster>
    );
}

const defaultOptions = { duration: 7500 };

export const toast = {
    success: (message: Message, options?: ToastOptions) =>
        toastFn.success(message, {
            ...(options || defaultOptions),
            icon: <HiCheckCircle className="text-success text-2xl" />,
        }),
    error: (message: Message, options?: ToastOptions) =>
        toastFn.error(message, {
            ...(options || defaultOptions),
            icon: <HiXCircle className="text-danger text-2xl" />,
        }),
    warning: (message: Message, options?: ToastOptions) =>
        toastFn.success(message, {
            ...(options || defaultOptions),
            icon: <HiInformationCircle className="text-warning text-2xl" />,
        }),
    info: (message: Message, options?: ToastOptions) =>
        toastFn.success(message, {
            ...(options || defaultOptions),
            icon: <HiExclamationCircle className="text-info text-2xl" />,
        }),
};

export default toast;
