'use client';

import Link from 'next/link';
import { FaFacebookSquare, FaInstagramSquare, FaTwitterSquare, FaYoutubeSquare } from 'react-icons/fa';

import {
    appointmentUrl,
    companyUrl,
    contactUrl,
    myAccountUrl,
    blogCategoryUrl,
    stateUrl,
    testsCategoryUrl,
    bulletinResultUrl,
} from 'src/services/route-utils';
import Logo from '../../public/images/logo.svg';

type FooterLink = {
    label: string;
    href: string;
    target?: string;
};

export default function Footer({
    categories,
    states,
    socialLinks,
    year,
}: {
    categories: any[];
    states: any[];
    socialLinks: any;
    year: string;
}) {
    const websiteLinks: FooterLink[] = [
        { label: 'Acasă', href: '/' },
        { label: 'Analize', href: testsCategoryUrl(null) },
        { label: 'Blog', href: blogCategoryUrl(null) },
        { label: 'Locații', href: stateUrl(null) },
        { label: 'Despre noi', href: companyUrl() },
        { label: 'Programări', href: appointmentUrl() },
        { label: 'Rezultate analize', href: bulletinResultUrl() },
        { label: 'Contul meu', href: myAccountUrl() },
        { label: 'Contact', href: contactUrl() },
    ];

    const supportLinks: FooterLink[] = [
        { label: 'Chestionar de satisfacție', href: '/chestionar-de-satisfactie' },
        { label: 'Satisfacția clientului', href: '/satisfactia-clientului' },
        { label: 'Protecția datelor cu caracter personal', href: '/protectia-datelor-cu-caracter-personal' },
        { label: 'Notă de informare GDPR', href: '/nota-informare-gdpr' },
        { label: 'Politica privind cookies', href: '/politica-privind-cookies' },
        { label: 'Termeni și condiții', href: '/termeni-si-conditii' },
        { label: 'ANPC', href: 'https://www.anpc.gov.ro/', target: '_blank' },
    ];

    const footerSection = (title: string) => <p className="font-body mb-3 font-bold uppercase">{title}</p>;

    const sectionItems = (items: FooterLink[], disablePrefetch?: boolean) =>
        items.map((item) => (
            <li className="mb-2" key={item.label}>
                <Link
                    className="hover:underline"
                    href={item.href}
                    prefetch={!disablePrefetch}
                    rel={disablePrefetch ? `nofollow${item.target ? ' noopener' : ''}` : undefined}
                    target={item.target}>
                    {item.label}
                </Link>
            </li>
        ));

    const socialIcon = (href: string, icon: React.JSX.Element) =>
        href ? (
            <Link href={href} legacyBehavior>
                <a className="mr-3 inline-block last:mr-0 hover:opacity-70">{icon}</a>
            </Link>
        ) : null;

    return (
        <footer className="bg-light-grey text-primary">
            <div className="container mx-auto w-full px-4 text-sm">
                <nav className="border-primary grid grid-cols-1 gap-4 border-b py-8 md:grid-cols-3 lg:grid-cols-8">
                    <div>
                        {footerSection('Website')}
                        <menu className="grid grid-cols-2 gap-x-4 md:grid-cols-1">{sectionItems(websiteLinks)}</menu>
                    </div>
                    <div className="col-span-2 hidden md:block">
                        {footerSection('Analize')}
                        <menu className="grid grid-cols-2 gap-x-4">
                            {sectionItems(
                                categories.map((category) => ({
                                    label: category.name,
                                    href: testsCategoryUrl(category),
                                })),
                            )}
                        </menu>
                    </div>
                    <div className="col-span-2 hidden md:block lg:col-span-3">
                        {footerSection('Locații')}
                        <menu className="grid grid-cols-2 gap-x-4 lg:grid-cols-3">
                            {sectionItems(
                                states.map((state) => ({
                                    label: state.name,
                                    href: stateUrl(state),
                                })),
                            )}
                        </menu>
                    </div>
                    <div className="lg:col-span-2">
                        {footerSection('Suport')}
                        <ul className="grid grid-cols-2 gap-x-4 md:grid-cols-1">{sectionItems(supportLinks, true)}</ul>
                    </div>
                </nav>
                <div className="grid grid-cols-1 items-center gap-y-4 py-8 md:grid-cols-2">
                    <div>
                        <Logo style={{ width: 200 }} alt="Bioclinica" title="Bioclinica" />
                        <p className="mt-2 text-xs">© Bioclinica {year}. Toate drepturile rezervate.</p>
                    </div>
                    <div className="text-3xl md:text-right">
                        {socialIcon(socialLinks.facebook, <FaFacebookSquare title="Facebook" />)}
                        {socialIcon(socialLinks.twitter, <FaTwitterSquare title="Twitter" />)}
                        {socialIcon(socialLinks.instagram, <FaInstagramSquare title="Instagram" />)}
                        {socialIcon(socialLinks.youtube, <FaYoutubeSquare title="YouTube" />)}
                    </div>
                </div>
            </div>
        </footer>
    );
}
