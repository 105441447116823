'use client';
import { useEffect, useState, useRef, ReactNode } from 'react';
import { useSwipeable } from 'react-swipeable';

import Arc from 'src/components/Arc';

type Props<T> = {
    items: T[];
    keyForItem: (item: T) => string;
    contentForItem: (item: T, active: boolean, index: number, shouldLoadImage: boolean) => ReactNode;
    duration?: number;
};

export default function Carousel<T>({ items, keyForItem, contentForItem, duration = 10000 }: Props<T>) {
    const interval = useRef<any>(undefined);
    const [activeStep, setActiveStep] = useState(0);
    const [slideVisiblePercentage, setSlideVisiblePercentage] = useState(1);

    const intervalStep = 100;
    const createInterval = () => {
        if (!interval.current) {
            interval.current = setInterval(() => {
                setSlideVisiblePercentage((slideVisiblePercentage) => slideVisiblePercentage - intervalStep / duration);
            }, intervalStep);
        }
    };

    const destroyInterval = () => {
        if (interval.current) {
            clearInterval(interval.current);
            interval.current = null;
        }
    };

    useEffect(() => {
        createInterval();

        return destroyInterval;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (slideVisiblePercentage <= 0) {
            setSlideVisiblePercentage(1);
            setActiveStep((activeStep) => (activeStep === items.length - 1 ? 0 : activeStep + 1));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slideVisiblePercentage]);

    const changeSlide = (newSlide) => {
        setSlideVisiblePercentage(1);
        setActiveStep(newSlide);
    };

    const swipeableHandlers = useSwipeable({
        onSwipedRight: () => {
            if (activeStep > 0) {
                setActiveStep((activeStep) => (activeStep > 0 ? activeStep - 1 : activeStep));
                setSlideVisiblePercentage(1);
            }
        },
        onSwipedLeft: () => {
            if (activeStep < items.length - 1) {
                setActiveStep((activeStep) => (activeStep < items.length - 1 ? activeStep + 1 : activeStep));
                setSlideVisiblePercentage(1);
            }
        },
        preventScrollOnSwipe: true,
        trackMouse: true,
    });

    return (
        <div
            className="relative h-full overflow-hidden"
            {...swipeableHandlers}
            onMouseEnter={destroyInterval}
            onMouseLeave={createInterval}>
            <ul
                className="flex h-full flex-nowrap transition-all duration-1000"
                style={{ transform: `translateX(-${activeStep * 100}%)` }}>
                {items.map((item, index) => (
                    <li key={keyForItem(item)} className="relative h-full w-full flex-none">
                        {contentForItem(item, index === activeStep, index, index === activeStep + 1)}
                    </li>
                ))}
            </ul>
            <div className="absolute right-4 top-6 w-full md:bottom-8 md:top-auto">
                <div className="container relative mx-auto px-4">
                    <CarouselIndicators
                        steps={items.length}
                        activeStep={activeStep}
                        slideVisiblePercentage={slideVisiblePercentage}
                        onIndicatorPressed={changeSlide}
                    />
                </div>
            </div>
        </div>
    );
}

function CarouselIndicators({
    steps,
    activeStep,
    slideVisiblePercentage = 1,
    onIndicatorPressed,
}: {
    steps: number;
    activeStep: number;
    slideVisiblePercentage?: number;
    onIndicatorPressed?: (index: number) => void;
}) {
    return (
        <div className="absolute right-0 top-0 flex gap-3">
            {[...Array(steps)].map((_, index) => (
                <Arc
                    className={`h-4 w-4 cursor-pointer rounded-full transition-all duration-300 bg-white/50${
                        activeStep === index ? ' scale-100' : ' scale-50'
                    }`}
                    key={index}
                    fill="#fff"
                    percentage={activeStep === index ? slideVisiblePercentage : 0}
                    onClick={() => {
                        if (typeof onIndicatorPressed === 'function') {
                            onIndicatorPressed(index);
                        }
                    }}
                />
            ))}
        </div>
    );
}
